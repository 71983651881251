
import state from "./moduleUintOwnerTypeState.js"
import mutations from "./moduleUintOwnerTypeMutations.js"
import getters from "./moduleUintOwnerTypeGetters.js"
import actions from './moduleUintOwnerTypeActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default {
  ADD_ITEM(state, item) {
    state.UintOwnerTypes.unshift(item);
  },
  SET_UintOwnerTypes(state, UintOwnerTypes) {
    state.UintOwnerTypes = UintOwnerTypes;
  },
  UPDATE_UintOwnerType(state, UintOwnerType) {
    const UintOwnerTypeIndex = state.UintOwnerTypes.findIndex(p => p.ID == UintOwnerType.ID);
    if (UintOwnerTypeIndex != -1) {
      Object.assign(state.UintOwnerTypes[UintOwnerTypeIndex], UintOwnerType);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.UintOwnerTypes.findIndex(p => p.ID == itemId);
    state.UintOwnerTypes.splice(ItemIndex, 1);
  }
};

